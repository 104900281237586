import styled from "styled-components";

export const PaginationContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: var(--max-content-width);
  margin: 0 auto;
  gap: var(--spacing-03);
  align-items: center;
  justify-content: center;
  > button {
    background: transparent;
    color: white;
    border: none;
    padding: var(--spacing-03) var(--spacing-05);
    cursor: pointer;
    &[aria-pressed="true"] {
      font-weight: bold;
      transform: scale(1.1);
      background: var(--secondary);
    }
  }
`;
