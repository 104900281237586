import React from "react";
import { PostData } from "../../api/types";
import { ArticleContainer, TitleHeader } from "./Article.styles";
import { formatRelative } from "date-fns";
import { nn } from "date-fns/locale";

type Props = {
  post: PostData;
  isPreview?: boolean;
};

export const Article = ({ post, isPreview }: Props) => {
  const featuredMedia =
    post?._embedded?.["wp:featuredmedia"] &&
    post._embedded["wp:featuredmedia"][0];
  const linkProps = isPreview ? { href: `/${post?.slug}` } : {};
  const LinkEl = isPreview ? Link : React.Fragment;
  return (
    <ArticleContainer isPreview={isPreview}>
      <TitleHeader
        isPreview={isPreview}
        featured_image={
          featuredMedia?.media_details?.sizes?.full?.source_url
        }
        onClick={
          isPreview
            ? () => (window.location.href = `/${post?.slug}`)
            : undefined
        }
      >
        <LinkEl {...linkProps}>
          <h2>{post.title.rendered}</h2>
          <aside>
            <span>{post?._embedded?.author?.[0]?.name}</span> |{" "}
            {formatRelative(new Date(post.date), new Date(), {
              locale: nn,
            })}
          </aside>
        </LinkEl>
      </TitleHeader>
      <div
        dangerouslySetInnerHTML={{
          __html: post.content.rendered,
        }}
        onClick={
          isPreview
            ? () => (window.location.href = `/${post?.slug}`)
            : undefined
        }
      />
    </ArticleContainer>
  );
};

const Link = (props: any) => <a {...props}>{props?.children}</a>;

export default Article;
