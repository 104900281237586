import React, { useState } from "react";
import {
  Nav,
  NavItems,
  StyledHeader,
  SubTitle,
  Title,
  ImageContainer,
  NavItemLink,
  NavItemButton,
  NavItemDropDown,
} from "./Header.styles";
import BG from "../../assets/hiim_1.jpeg";
import FavIcon from "../../assets/FavIcon";
import ChevronDown from "../../assets/ChevronDown";
import ArrowBack from "../../assets/ArrowBack";
import { useHistory } from "react-router-dom";
import { useContext } from "react";
import { AppContext } from "../core/App/constants";
import { useEffect } from "react";

export const Header = () => {
  const [dropdownActive, setDropdownActive] = useState(false);
  const { categories, selectedCategory, setCategory } =
    useContext(AppContext);
  const { location } = useHistory();
  const indexActive = location.pathname === "/";

  const clickListener = (e: MouseEvent) => {
    const el = e.target as HTMLElement;
    if (!el?.classList?.contains?.("__sbc")) {
      setDropdownActive(false);
    }
  };

  useEffect(() => {
    if (dropdownActive) {
      document.body.addEventListener("click", clickListener);
    } else {
      document.body.removeEventListener("click", clickListener);
    }
    return () => {
      document.body.removeEventListener("click", clickListener);
    };
  }, [dropdownActive]);

  return (
    <>
      <StyledHeader onClick={() => (window.location.href = "/")}>
        <ImageContainer>
          <img src={BG} alt="Bilde av Hiim" />
        </ImageContainer>
        <div>
          <Title>
            <FavIcon /> Hiimaord
          </Title>
          <SubTitle>
            Blogger om små og store saker midt i livet
          </SubTitle>
        </div>
      </StyledHeader>
      <Nav>
        <NavItems>
          {indexActive ? (
            <>
              <li>
                <NavItemButton
                  onClick={() => setCategory(undefined)}
                  active={selectedCategory === undefined}
                >
                  Alle innlegg
                </NavItemButton>
              </li>
              {categories
                .filter((c) => c.id !== 1)
                .map((category) => (
                  <li>
                    <NavItemButton
                      onClick={() => setCategory(category.id)}
                      active={selectedCategory === category.id}
                    >
                      {category.name}
                    </NavItemButton>
                  </li>
                ))}
            </>
          ) : (
            <NavItemLink href="/">
              <ArrowBack /> Alle innlegg
            </NavItemLink>
          )}
        </NavItems>
      </Nav>
    </>
  );
};

export default Header;
