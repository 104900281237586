import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { createGlobalStyle } from "styled-components";
import { useGetPostBySlug } from "../../api/posts/get";
import Article from "../Article/Article";
import { AppContainer } from "../core/App/App.styles";
import { ArticlePageContainer } from "./ArticlePage.styles";

const BGStyles = createGlobalStyle`
  ${AppContainer} {
    background: var(--ui-02);
  }
`;

export const ArticlePage = () => {
  const [pageWidth, setPageWidth] = useState(0);
  const pageRef = useRef<HTMLDivElement>(null);
  const { slug } = useParams<{ slug: string }>();
  const { loading, error, data: post } = useGetPostBySlug(slug);

  useEffect(() => {
    if (pageRef.current) {
      setPageWidth(pageRef.current.clientWidth);
      setTimeout(() => {
        (window as any)?.FB?.XFBML?.parse?.();
      }, 1000);
    }
  }, [pageRef]);
  return (
    <ArticlePageContainer ref={pageRef}>
      <BGStyles />
      {!loading && !error && post && (
        <Helmet>
          <title>{post.title.rendered} - Hiimaord</title>
        </Helmet>
      )}
      {error && (
        <h2>404 - Innlegget du leiter etter finnes ikkje.</h2>
      )}
      {!loading && !error && post && <Article post={post} />}

      {pageWidth !== 0 && (
        <>
          <div
            className="fb-like"
            data-href={window.location.href}
            data-width={pageWidth - 32}
            data-layout="button_count"
            data-action="like"
            data-size="large"
            data-share="false"
          ></div>
          <div
            className="fb-comments"
            data-href={window.location.href}
            data-width={pageWidth - 32}
            data-numposts="100"
            data-colorscheme="light"
          ></div>
        </>
      )}
    </ArticlePageContainer>
  );
};

export default ArticlePage;
