import * as React from "react";

function FavIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={75.444}
      height={83.614}
      viewBox="0 0 19.961 22.123"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.031 18.58s.821.66.852 1.295c.02.389-2.348.87-3.27.691l1.187 1.54c2.465.255 3.602-2.41 3.602-2.41l-1.843-3.044z"
        fill="#483737"
      />
      <path
        d="M12.145 19.226s-7.232-.542-10.797-7.368C-.652 8.028.166 0 .166 0s3.056 3.277 7.371 10.732c1.6 2.765 4.608 8.494 4.608 8.494z"
        fill="#483737"
      />
      <path
        d="M12.145 19.226s-5.127-5.037-5.824-4.85l-.89.239.355-.844c.59-1.403-.272-2.793-1.706-4.25C.68 6.065.166 0 .166 0S3.4 3.776 7.168 4.541c.888.18-.527 1.817-.527 1.817s1.354-1.44 2.411-.333c1.057 1.108.923 1.548 1.41 2.963 1.079 3.126 1.683 10.238 1.683 10.238z"
        fill="#6c5353"
      />
      <path
        d="M12.145 19.226s5.425-1.179 7.402-6.731c1.11-3.116-.362-9.115-.362-9.115s-1.97 2.808-4.45 8.917c-.919 2.267-2.59 6.929-2.59 6.929z"
        fill="#483737"
      />
      <path
        d="M12.145 19.226s2.423-4.002 2.971-3.934l1.164.396-.616-1.685s1.56-1.426 1.564-2.99c.013-4.195 1.957-7.633 1.957-7.633s-2.15 1.5-2.724 1.89c-.574.39.592 1.322.592 1.322s-1.18-.949-1.863.003c-.684.952-2.638 3.567-2.857 4.691-.485 2.485-.188 7.94-.188 7.94z"
        fill="#6c5353"
      />
      <path
        d="M13.24 10.72l-1.783 5.201-2.11-4.402 2.5 7.193 2.502-3.094z"
        fill="#6c5353"
      />
    </svg>
  );
}

export default FavIcon;
