import styled from "styled-components";
import { ARTICLE_STYLES } from "../../common/styles";

export const ArticleContainer = styled.article<{
  isPreview?: Boolean;
}>`
  width: 100%;
  max-width: var(--max-content-width);
  margin: 0 auto;
  animation: fadeInPost 0.2s ease-in-out;
  @keyframes fadeInPost {
    from {
      opacity: 0;
      transform: translateY(2px);
    }
  }
  ${({ isPreview }) =>
    isPreview &&
    `
  cursor:pointer;
  &:hover h2 {
    text-decoration: underline;
    text-decoration-color: var(--text-03);
  }
  `}
  > div {
    background: var(--ui-02);
    padding: var(--spacing-05);
    ${ARTICLE_STYLES}
    ${({ isPreview }) =>
      isPreview &&
      `
        max-height:10rem;
        overflow:hidden;
        position:relative;
        &::after{
          content:"";
          text-align:center;
          position:absolute;
          bottom:0;
          left:0;
          right:0;
          pointer-events:none;
          height:4rem;
          background:linear-gradient(to top, var(--ui-bg), rgba(0,0,0,0));
        }
      `}
  }
  p,
  h2 {
    margin: 0;
  }
`;

type PreviewHeaderProps = {
  featured_image?: string;
  isPreview?: boolean;
};

export const TitleHeader = styled.header<PreviewHeaderProps>`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: var(--spacing-03);
  padding: var(--spacing-05);
  text-shadow: 0px 1px 0px #000000;
  > a {
    display: block;
    width: 100%;
    color: inherit;
    text-decoration: none;
  }
  h2 {
    margin: 0;
    font-size: 1.875rem;
    position: relative;
    z-index: 2;
  }
  aside {
    position: relative;
    z-index: 2;
    color: var(--text-03);
    font-size: 0.85rem;
    span {
      color: var(--text-02);
      font-weight: bold;
    }
  }
  ${({ featured_image }) =>
    featured_image &&
    `
      background-image:url("${featured_image}");
      background-repeat:no-repeat;
      background-size:cover;
      box-shadow: 0px 2px 20px -11px #000;
      min-height:9rem;
      justify-content:flex-end;
      position:relative;
      &::before {
        content:"";
        position:absolute;
        background:linear-gradient(to top, var(--ui-bg), rgba(0,0,0,0));
        left:0;
        bottom:0;
        right:0;
        height:7rem;
      }
    `}
`;
