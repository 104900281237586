import styled, { css } from "styled-components";

export const StyledHeader = styled.header`
  padding: var(--spacing-07);
  background: linear-gradient(
    to top left,
    hsl(var(--ui-01-hsl) / 0.9),
    hsl(20deg 26% 54%)
  );
  // hsl(var(--ui-01-hsl) / 0.25)
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  min-height: 15rem;
  position: relative;
  z-index: 2;
  cursor: pointer;
  h1 {
    font-size: 3rem;
  }
  > div {
    width: 100%;
    max-width: var(--max-content-width);
  }
`;

export const ImageContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  z-index: -1;
  max-width: 100% !important;
  img {
    width: 100vw;
    width: 100%;
    object-fit: cover;
    opacity: 0.15;
    filter: grayscale(100%) blur(0px);
    user-select: none;
    pointer-events: none;
  }
`;

export const Title = styled.h1`
  margin: 0 0 var(--spacing-03);
  > svg {
    height: 1em;
    width: 1em;
  }
`;

export const SubTitle = styled.p`
  color: var(--text-02);
  margin: 0;
`;

export const Nav = styled.nav`
  display: flex;
  max-width: 100%;
  width: 100%;
  background: var(--nav-bg);
  position: relative;
  z-index: 99;
  min-height: 53px;
`;

export const NavItems = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  width: 100%;
  max-width: var(--max-content-width);
  margin: 0 auto;
`;

type NavItemProps = {
  active?: boolean;
};

const NAV_ITEM_STYLES = css`
  display: flex;
  align-items: center;
  gap: var(--spacing-03);
  color: var(--text-01);
  padding: var(--spacing-05);
  background: transparent;
  border: none;
  cursor: pointer;
  border-bottom: 3px solid transparent;
  font-size: 0.875rem;
  font-family: inherit;
  text-decoration: none;
  transition: all 0.15s ease-in;
  svg {
    fill: var(--text-01);
    --size: 0.85rem;
    height: var(--size);
    width: var(--size);
  }
  &:hover {
    background: var(--ui-bg);
    transform: translateY(0px);
  }
`;

export const NavItemLink = styled.a<NavItemProps>`
  border-bottom-color: ${(props) =>
    props.active ? "var(--primary)" : "transparent"};
  ${NAV_ITEM_STYLES}
`;

export const NavItemDropDown = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  min-width: 150px;
  background: var(--ui-bg);
  z-index: 99;
  display: flex;
  flex-direction: column;
  > button {
    display: flex;
    border: none;
    text-align: left;
    padding: var(--spacing-03);
    border-radius: 0px;
    border-bottom: 1px solid var(--ui-02);
    cursor: pointer;
    font-family: inherit;
    background: #eadbe3;
  }
`;

export const NavItemButton = styled.button<NavItemProps>`
  border-bottom-color: ${(props) =>
    props.active ? "var(--primary)" : "transparent"};
  ${NAV_ITEM_STYLES}
  position:relative;
  background: ${(props) =>
    props.active ? "var(--ui-bg)" : "transparent"};
  animation: fadeIn 0.2s ease-in-out;
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
  }
  ${(props) =>
    props.active &&
    `
    &:hover {
      transform: translateY(3px);
    }
    border-radius:5px;
    border-bottom-left-radius:0px;
    border-bottom-right-radius:0px;
  `}
`;
