import styled from "styled-components";

export const ArticlePageContainer = styled.div`
  width: 100%;
  max-width: var(--max-content-width);
  margin: var(--spacing-07) auto;
  padding-bottom: var(--spacing-10);
  .fb-like {
    margin: var(--spacing-07) auto;
  }
  .fb-comments {
    display: flex;
    background: white;
    opacity: 0.95;
    border-radius: 3px;
    padding: var(--spacing-02) var(--spacing-03);
  }
`;
