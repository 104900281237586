import axios from "axios";
import { useEffect, useState } from "react";
import { API_URL } from "../../constants";
import { BaseHookReturn, Category, PostData } from "../types";

type WPOrder =
  | "author"
  | "date"
  | "id"
  | "include"
  | "modified"
  | "parent"
  | "relevance"
  | "slug"
  | "include_slugs"
  | "title";

type UseGetPostsParams = {
  orderBy?: WPOrder;
  order?: "desc" | "asc";
  pageNumber?: number;
  perPage?: number;
  categoryId?: number;
};

export function useGetPosts({
  orderBy,
  order,
  pageNumber = 1,
  perPage = 20,
  categoryId,
}: UseGetPostsParams): BaseHookReturn<PostData[]> & {
  totalPages?: number;
} {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<PostData[]>();
  const [totalPosts, setTotalPosts] = useState<
    undefined | number
  >();

  const appendToUrl: string[] = [
    `per_page=${perPage}`,
    `page=${pageNumber}`,
  ];

  if (orderBy) {
    appendToUrl.push(`orderBy=${orderBy}`);
  }
  if (order) {
    appendToUrl.push(`order=${order}`);
  }
  if (categoryId) {
    appendToUrl.push(`categories=${categoryId}`);
  }

  const request_url = `${API_URL}/wp-json/wp/v2/posts?${appendToUrl.join(
    "&"
  )}&_embed`;

  const totalPages =
    totalPosts && perPage ? Math.ceil(totalPosts / perPage) : 0;

  const getPosts = () => {
    setLoading(true);
    axios
      .get<PostData[]>(request_url)
      .then((res) => {
        const headers = res.headers as {
          "x-wp-totalpages"?: number;
          "x-wp-total"?: number;
        };
        setData(res.data);
        if (headers["x-wp-total"]) {
          setTotalPosts(+headers["x-wp-total"]);
        }
        setLoading(false);
      })
      .catch((err) => setLoading(false));
  };

  useEffect(() => {
    getPosts();
  }, [orderBy, order, perPage, pageNumber, categoryId]);

  return {
    loading,
    error: !loading && !data,
    data: data ?? [],
    totalPages,
  };
}

export function useGetCategories(): BaseHookReturn<Category[]> {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<Category[]>();

  const getCategories = () => {
    setLoading(true);
    axios
      .get<Category[]>(
        `${API_URL}/wp-json/wp/v2/categories?perPage=100&page=1`
      )
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((err) => setLoading(false));
  };

  useEffect(() => getCategories(), []);

  return {
    loading,
    error: !loading && !data,
    data: data ?? [],
  };
}

export function useGetPostBySlug(
  slug: string
): BaseHookReturn<PostData> {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<PostData>();

  useEffect(() => {
    axios
      .get<PostData[]>(
        `${API_URL}/wp-json/wp/v2/posts?_embed&slug=${slug}`
      )
      .then((res) => {
        setData(res.data?.[0]);
        setLoading(false);
      })
      .catch((err) => setLoading(false));
  }, []);

  return {
    loading,
    error: !loading && !data,
    data: data ?? null,
  };
}
