import { BrowserRouter, Switch, Route } from "react-router-dom";
import { AppContainer } from "./App.styles";
import { Header } from "../../Header";
import { ROUTES } from "../../../constants";
import { AppContext } from "./constants";
import { useGetCategories } from "../../../api/posts/get";
import { useState } from "react";
import { AppContextT } from "../../../api/types";

export function App() {
  const [selectedCategory, setSelectedCategory] = useState<
    number | undefined
  >();
  const { data: categories } = useGetCategories();

  const appContext: AppContextT = {
    categories: categories ?? [],
    selectedCategory,
    setCategory: setSelectedCategory,
  };

  return (
    <BrowserRouter>
      <AppContainer>
        <AppContext.Provider value={appContext}>
          <Header />
          <Switch>
            {ROUTES.map((route) => (
              <Route {...route} />
            ))}
          </Switch>
          <footer>
            <a
              href="https://blogg.livhiim.no/wp-login.php"
              target="_blank"
              rel="noreferrer"
            >
              Logg inn
            </a>
          </footer>
        </AppContext.Provider>
      </AppContainer>
    </BrowserRouter>
  );
}

export default App;
