import React, { useState } from "react";
import { useContext } from "react";
import { Helmet } from "react-helmet";
import { useGetPosts } from "../../api/posts/get";
import { Article } from "../Article";
import { AppContext } from "../core/App/constants";
import { Pagination } from "../Pagination";
import {
  ArticleListContainer,
  StatusMessage,
} from "./ArticleList.styles";

export const ArticleList = () => {
  const perPage = 10;
  const [pageNumber, setPageNumber] = useState(1);
  const { selectedCategory } = useContext(AppContext);
  const {
    data: posts,
    error,
    loading,
    totalPages,
  } = useGetPosts({
    orderBy: "date",
    order: "desc",
    perPage,
    pageNumber,
    categoryId: selectedCategory,
  });
  return (
    <ArticleListContainer>
      <Helmet>
        <title>Hiimaord</title>
      </Helmet>
      {loading || error ? (
        <StatusMessage>
          {error
            ? "Kunne ikkje laste inn artiklar."
            : "Laster inn..."}
        </StatusMessage>
      ) : posts && posts?.length > 0 ? (
        posts?.map((post) => <Article post={post} isPreview />)
      ) : (
        <StatusMessage>
          Klarte ikkje å finne nokon innlegg. 😔
        </StatusMessage>
      )}
      {!loading && !error && totalPages && totalPages > 1 ? (
        <Pagination
          totalPages={totalPages ?? 0}
          currentPage={pageNumber}
          onChange={setPageNumber}
        />
      ) : null}
    </ArticleListContainer>
  );
};

export default ArticleList;
