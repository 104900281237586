import React from "react";
import { PaginationContainer } from "./Pagination.styles";

type Props = {
  currentPage: number;
  totalPages: number;
  onChange: (newPage: number) => void;
};

export const Pagination = ({
  totalPages,
  currentPage,
  onChange,
}: Props) => {
  const mapper = [];
  for (let i = 0; i < totalPages; i++) {
    mapper.push(i + 1);
  }
  return (
    <PaginationContainer>
      {mapper.map((pageNumber) => (
        <button
          key={pageNumber}
          onClick={() => onChange(pageNumber)}
          aria-pressed={currentPage === pageNumber}
        >
          {pageNumber}
        </button>
      ))}
    </PaginationContainer>
  );
};

export default Pagination;
