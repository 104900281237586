import styled from "styled-components";

export const ArticleListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-07);
  width: 100%;
  padding: 0 var(--spacing-07);
  margin: var(--spacing-07) 0;
`;

export const StatusMessage = styled.div`
  text-align: center;
  color: var(--text-02);
  font-weight: bold;
`;
