import { RouteProps } from "react-router-dom";
import { ArticleList } from "./components/ArticleList";
import { ArticlePage } from "./components/ArticlePage";

export const API_URL = `https://blogg.livhiim.no`;

export const ROUTES: RouteProps[] = [
  {
    path: "/:slug",
    component: ArticlePage,
  },
  {
    path: "/",
    exact: true,
    component: ArticleList,
  },
];
