import { css } from "styled-components";

export const CSS_VARIABLES = css`
  --primary-hex: #f59363;
  --primary-h: 20;
  --primary-s: 88%;
  --primary-l: 67%;
  --primary-hsl: var(--primary-h), var(--primary-s),
    var(--primary-l);
  --primary: hsl(var(--primary-hsl));
  --secondary: #402634;

  //--ui-bg: #181d27;
  --nav-bg: var(--secondary);
  --ui-bg: #0c121d;
  --ui-01: #262f40;
  --ui-01-hsl: 219 25% 20%;
  //--ui-02: #1f242d;
  --ui-02: #251f2d;
  --ui-03: #31353e;
  --ui-04: #41506d;

  --text-01: #f3f1dd;
  --text-02: #f3dac4;
  --text-03: #888672;

  --max-content-width: 750px;

  --spacing-01: 0.125rem;
  --spacing-02: 0.25rem;
  --spacing-03: 0.5rem;
  --spacing-04: 0.75rem;
  --spacing-05: 1rem;
  --spacing-06: 1.5rem;
  --spacing-07: 2rem;
  --spacing-08: 2.5rem;
  --spacing-09: 3rem;
  --spacing-10: 4rem;
  --spacing-11: 5rem;
  --spacing-12: 6rem;
  --spacing-13: 10rem;
`;
