import styled from "styled-components";

export const AppContainer = styled.div`
  height: 100%;
  min-height: 100vh;
  background: var(--ui-bg);
  color: var(--text-01);
  > footer {
    text-align: center;
    margin: var(--spacing-10) 0;
    font-size: 0.875rem;
    a {
      opacity: 0.8;
      color: var(--text-02);
      &:hover {
        text-decoration: none;
        opacity: 1;
      }
    }
  }
`;
