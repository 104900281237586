import { css } from "styled-components";

/** Used in article body */
export const ARTICLE_STYLES = css`
  p {
    margin: 0 0 var(--spacing-05);
    line-height: 1.4em;
    font-size: 0.875rem;
  }
  a {
    color: var(--primary);
    &:hover {
      text-decoration: none;
    }
  }
  img {
    margin: var(--spacing-05) 0;
  }
  blockquote {
    background: var(--secondary);
    border-left: 10px solid var(--primary);
    padding: var(--spacing-05);
    margin: var(--spacing-05) 0;
  }
`;
