import "./imports.css";
import { createGlobalStyle } from "styled-components";
import { CSS_VARIABLES } from "./variables";

export const GlobalStyles = createGlobalStyle`
    *, *::before, *::after {
        box-sizing:inherit;
    }
    body, html {
        margin:0;
        padding:0;
        display:grid;
        height:100%;
        font-family: 'Montserrat', sans-serif;
        align-items:flex-start;
    }
    body {
        box-sizing:border-box;
        ${CSS_VARIABLES}
        background:var(--ui-bg);
    }
    img {
        display:block;
        max-width:100%;
    }
    h1,h2,h3,h4 {margin:0 0 var(--spacing-05);}
`;
